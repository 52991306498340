import './App.css';
import axios from 'axios'
import React, {useState, useEffect} from 'react'
import QRCode from 'qrcode'
import "./fonts/DFVNObelixPro.ttf"

function App() {

  const [pwidth, setPwidth] = useState('')
  const [pheight, setPheight] = useState('')
  const [isTopViewVisible, setTopViewVisible] = useState(true)
  const [inputValue, setInputValue] = useState('');
  const [texttile, setTexttile] = useState('')
  const [content, setContent] = useState('')


  const hideTopView = async() => {
    setTopViewVisible(false)


    const bbb = inputValue


    async function handleTicketResponse(response) {
      if (response.data.data.length > 0) {
        showQR(response.data.data[0].SN);
        console.log(response.data.data[0].SN);
        setContent(response.data.data[0].phone);
        console.log(response.data.data[0].PSN);
        const VIP = response.data.data[0].PSN
        if (VIP == 'VIP')
          qrContainer(2);
        else 
          qrContainer(1);
        return true; // Indicate that data was found and processed
      }
      return false; // Indicate that no data was found
    }

    async function getTicket(phone) {
      const response = await axios.get('https://6868gaming.online/api/transactions/ticket?phone='+ phone+'&giftname=fanzone-minigame')
      const hasData = await handleTicketResponse(response);
  
      // if (response.data.data.length > 0)
      // {
      //   showQR(response.data.data[0].SN)
      //   console.log(response.data.data[0].SN)
      //   setContent(response.data.data[0].phone)

      //   qrContainer(1)
      // }
    }

    async function getTicketVIP(phone) {
      const response = await axios.get('https://6868gaming.online/api/transactions/ticket?SN='+ phone)
      const hasData = await handleTicketResponse(response);

      if (!hasData) {
        await getTicket(phone); // Call getTicketVIP if no data was found
      }


    }

    getTicketVIP(bbb)

    // getTicketVIP(bbb)

  }

  const gotoMP = () => {
    // window.location.href= 'https://hypalooza.com'
  }

    // Handler to update the input value
  const handleInputChange = (event) => {
    console.log(event.target.value)
      setInputValue(event.target.value);
  };



  useEffect (() => {
      qrContainer(0)

  },[])

  const showQR = async (content) => {
    try {      
      const options = {
        color: {
          dark: '#fff',  // QR code color
          light: '#ffffff00' // Background color (transparent)
        },
        width: 250, // QR code size
        padding: 10
      };
      
      QRCode.toCanvas(document.getElementById("canvas"),content, options)  
    } catch (error) {
      // xử lý khi gọi api thất bại
      console.log(error);
    }
  };

  const qrContainer = async(param1) => {
    const _mainPage = document.getElementById('mainPage')
    setPwidth(_mainPage.clientWidth)
    setPheight(_mainPage.clientHeight)
    
    // khong trung
    if (param1 == 0)
    {
      setTexttile('SỐ ĐIỆN THOẠI CỦA BẠN')
      setContent('CHƯA HỢP LỆ')
    }else if (param1 == 1) {
      setTexttile('')
      setContent('MÃ VÉ FANZONE')

    }else if (param1 == 2) {
      setTexttile('')
      setContent('MÃ VÉ VIP FANZONE')

    }

  }



  return (
    <div className="App" id="mainPage">
      <header className="App-header">
      {isTopViewVisible && (
          <div className="top-view" style={{ width: pwidth, height: pheight, fontSize: 18}}>
            <input
              type="text"
              value={inputValue}
              onChange={handleInputChange}
              placeholder="Số điện thoại hoặc mã vé của bạn"
            />
            <button onClick={hideTopView} style={{ fontFamily: "DFVN"}}>Tiếp tục</button>
          </div>
        )}
        <div className="page"  >
          <div className="section-container-qr"id="qrdiv" style={{ width: pwidth, height: pheight, backgroundImage : 'url("https://zdn.6868gaming.online/images/9.jpg'}} >        
          {!isTopViewVisible && (

            <div >
              <p style={{ fontFamily: "DFVN", color: 'white', fontSize: 30}}> {texttile}</p>
              <p style={{ fontFamily: "DFVN", color: 'yellow', fontSize: 30}}> {content}</p>
              <canvas id="canvas" style={{ }}></canvas>   
              <button id="btntt" onClick = {gotoMP} style={{ fontFamily: "DFVN"}}>Hoàn Thành</button>
            </div>

          )}
            
          </div>
        </div>
      </header>
    </div>
  );
}

export default App;
